/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/

// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;

// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
  --ion-color-primary: #fee661;
  --ion-color-primary-rgb: 254,230,97;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #e0ca55;
  --ion-color-primary-tint: #fee971;

  --ion-color-secondary: #fafafa;
  --ion-color-secondary-rgb: 250,250,250;
  --ion-color-secondary-contrast: #45dd85;
  --ion-color-secondary-contrast-rgb: 69,221,133;
  --ion-color-secondary-shade: #dcdcdc;
  --ion-color-secondary-tint: #fbfbfb;

  --ion-color-tertiary: #fafafa;
  --ion-color-tertiary-rgb: 250,250,250;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #dcdcdc;
  --ion-color-tertiary-tint: #fbfbfb;

  --ion-color-success: #45dd85;
  --ion-color-success-rgb: 69,221,133;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #3dc275;
  --ion-color-success-tint: #58e091;

  --ion-color-warning: #e0ca55;
  --ion-color-warning-rgb: 224,202,85;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #c5b24b;
  --ion-color-warning-tint: #e3cf66;

  --ion-color-danger: #FF6471;
  --ion-color-danger-rgb: 255,100,113;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e05863;
  --ion-color-danger-tint: #ff747f;

  --ion-color-dark: #000000;
  --ion-color-dark-rgb: 0,0,0;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #000000;
  --ion-color-dark-tint: #1a1a1a;

  --ion-color-medium: #878396;
  --ion-color-medium-rgb: 135,131,150;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #777384;
  --ion-color-medium-tint: #938fa1;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;
}

/*
 * Additional Ionic Colors
 * ----------------------------------------------------------------------------
 * In order to add colors to be used within Ionic components,
 * the color should be added as a class with the convention `.ion-color-{COLOR}`
 * where `{COLOR}` is the color to be used on the Ionic component.
 * For more information on adding new colors, please see
 * https://ionicframework.com/docs/theming/colors#adding-colors
 *
 * To generate the code for a new color, check out our new color creator:
 * https://ionicframework.com/docs/theming/colors#new-color-creator
*/

:root {
  --ion-color-clan-list-bg: #FAFAFA;
  --ion-color-clan-list-bg-rgb: 250,250,250;
  --ion-color-clan-list-bg-contrast: #000000;
  --ion-color-clan-list-bg-contrast-rgb: 0,0,0;
  --ion-color-clan-list-bg-shade: #dcdcdc;
  --ion-color-clan-list-bg-tint: #fbfbfb;

  --ion-color-clanicon: #868396;
  --ion-color-clanicon-rgb: 134,131,150;
  --ion-color-clanicon-contrast: #000000;
  --ion-color-clanicon-contrast-rgb: 0,0,0;
  --ion-color-clanicon-shade: #767384;
  --ion-color-clanicon-tint: #928fa1;

  --ion-color-toast: #878396;
  --ion-color-toast-rgb: 135,131,150;
  --ion-color-toast-contrast: #ffffff;
  --ion-color-toast-contrast-rgb: 255,255,255;
  --ion-color-toast-shade: #777384;
  --ion-color-toast-tint: #938fa1;
}

.ion-color-clan-list-bg {
  --ion-color-base: var(--ion-color-clan-list-bg);
  --ion-color-base-rgb: var(--ion-color-clan-list-bg-rgb);
  --ion-color-contrast: var(--ion-color-clan-list-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-clan-list-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-clan-list-bg-shade);
  --ion-color-tint: var(--ion-color-clan-list-bg-tint);
}

.ion-color-clanicon {
  --ion-color-base: var(--ion-color-clanicon);
  --ion-color-base-rgb: var(--ion-color-clanicon-rgb);
  --ion-color-contrast: var(--ion-color-clanicon-contrast);
  --ion-color-contrast-rgb: var(--ion-color-clanicon-contrast-rgb);
  --ion-color-shade: var(--ion-color-clanicon-shade);
  --ion-color-tint: var(--ion-color-clanicon-tint);
}

.ion-color-toast {
  --ion-color-base: var(--ion-color-toast);
  --ion-color-base-rgb: var(--ion-color-toast-rgb);
  --ion-color-contrast: var(--ion-color-toast-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toast-contrast-rgb);
  --ion-color-shade: var(--ion-color-toast-shade);
  --ion-color-tint: var(--ion-color-toast-tint);
}

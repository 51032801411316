/* Not using base and components because Ionic offers it's own */

/*
  @import "tailwindcss/base";
  @import "tailwindcss/components";
*/

/* If needed add custom Tailwind component classes before your utilities */

.bg-white{
  --bg-opacity: 1 !important;
  background-color: #fff !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.bg-gray-100{
  --bg-opacity: 1 !important;
  background-color: #f7fafc !important;
  background-color: rgba(247, 250, 252, var(--bg-opacity)) !important;
}

.bg-clanGray-100{
  --bg-opacity: 1 !important;
  background-color: #fafafa !important;
  background-color: rgba(250, 250, 250, var(--bg-opacity)) !important;
}

.bg-clanYellow-100{
  --bg-opacity: 1 !important;
  background-color: #fee661 !important;
  background-color: rgba(254, 230, 97, var(--bg-opacity)) !important;
}

.border-gray-100{
  --border-opacity: 1 !important;
  border-color: #f7fafc !important;
  border-color: rgba(247, 250, 252, var(--border-opacity)) !important;
}

.border-gray-200{
  --border-opacity: 1 !important;
  border-color: #edf2f7 !important;
  border-color: rgba(237, 242, 247, var(--border-opacity)) !important;
}

.border-clanGreen-100{
  --border-opacity: 1 !important;
  border-color: #45dd85 !important;
  border-color: rgba(69, 221, 133, var(--border-opacity)) !important;
}

.border-clanYellow-100{
  --border-opacity: 1 !important;
  border-color: #fee661 !important;
  border-color: rgba(254, 230, 97, var(--border-opacity)) !important;
}

.border-opacity-75{
  --border-opacity: 0.75 !important;
}

.rounded-md{
  border-radius: 0.375rem !important;
}

.rounded-full{
  border-radius: 9999px !important;
}

.rounded-clanCard{
  border-radius: 0.9375rem !important;
}

.rounded-clanListItem{
  border-radius: 1.78rem !important;
}

.rounded-b-clanCard{
  border-bottom-right-radius: 0.9375rem !important;
  border-bottom-left-radius: 0.9375rem !important;
}

.border-solid{
  border-style: solid !important;
}

.border-2{
  border-width: 2px !important;
}

.border-4{
  border-width: 4px !important;
}

.border{
  border-width: 1px !important;
}

.border-t-0{
  border-top-width: 0 !important;
}

.border-r-0{
  border-right-width: 0 !important;
}

.border-b-0{
  border-bottom-width: 0 !important;
}

.border-l-0{
  border-left-width: 0 !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.block{
  display: block !important;
}

.flex{
  display: flex !important;
}

.table{
  display: table !important;
}

.grid{
  display: grid !important;
}

.hidden{
  display: none !important;
}

.flex-row{
  flex-direction: row !important;
}

.flex-row-reverse{
  flex-direction: row-reverse !important;
}

.flex-col{
  flex-direction: column !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.flex-no-wrap{
  flex-wrap: nowrap !important;
}

.items-center{
  align-items: center !important;
}

.self-start{
  align-self: flex-start !important;
}

.self-end{
  align-self: flex-end !important;
}

.self-center{
  align-self: center !important;
}

.self-stretch{
  align-self: stretch !important;
}

.justify-start{
  justify-content: flex-start !important;
}

.justify-end{
  justify-content: flex-end !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.justify-around{
  justify-content: space-around !important;
}

.flex-1{
  flex: 1 1 0% !important;
}

.flex-auto{
  flex: 1 1 auto !important;
}

.flex-grow{
  flex-grow: 1 !important;
}

.flex-shrink-0{
  flex-shrink: 0 !important;
}

.flex-shrink{
  flex-shrink: 1 !important;
}

.font-gilroy{
  font-family: Gilroy !important;
}

.font-notoSans{
  font-family: NotoSans !important;
}

.font-notoSansRegular{
  font-family: NotoSansRegular !important;
}

.font-light{
  font-weight: 300 !important;
}

.font-normal{
  font-weight: 400 !important;
}

.font-bold{
  font-weight: 700 !important;
}

.font-extrabold{
  font-weight: 800 !important;
}

.h-2{
  height: 0.5rem !important;
}

.h-4{
  height: 1rem !important;
}

.h-6{
  height: 1.5rem !important;
}

.h-8{
  height: 2rem !important;
}

.h-10{
  height: 2.5rem !important;
}

.h-12{
  height: 3rem !important;
}

.h-16{
  height: 4rem !important;
}

.h-36{
  height: 9rem !important;
}

.h-40{
  height: 10rem !important;
}

.h-clanCard{
  height: 90% !important;
}

.h-clanBtn{
  height: 2.5rem !important;
}

.h-clanAvatar{
  height: 12.5rem !important;
}

.h-clanProfileAvatar{
  height: 12.5rem !important;
}

.h-full{
  height: 100% !important;
}

.h-clanCardCircle{
  height: 12rem !important;
}

.h-clanProfileAvatarSmall{
  height: 2.15rem !important;
}

.h-clanCardLong{
  height: 70vh !important;
}

.h-clanCardFull{
  height: 80vh !important;
}

.text-sm{
  font-size: 0.875rem !important;
}

.text-base{
  font-size: 1rem !important;
}

.text-2xl{
  font-size: 1.5rem !important;
}

.text-3xl{
  font-size: 1.875rem !important;
}

.text-5xl{
  font-size: 3rem !important;
}

.text-clanH5{
  font-size: 0.8571rem !important;
  line-height: 1rem !important;
}

.text-clanH4{
  font-size: 1rem !important;
  line-height: 1.286rem !important;
}

.text-clanH2{
  font-size: 1.714rem !important;
  line-height: 2rem !important;
}

.text-clanP{
  font-size: 1rem !important;
  line-height: 1.286rem !important;
}

.leading-6{
  line-height: 1.5rem !important;
}

.m-0{
  margin: 0 !important;
}

.m-1{
  margin: 0.25rem !important;
}

.m-2{
  margin: 0.5rem !important;
}

.m-4{
  margin: 1rem !important;
}

.m-8{
  margin: 2rem !important;
}

.m-clanRelativeFillScreen{
  margin: 0 -50vw !important;
}

.my-0{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-1{
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-1{
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2{
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3{
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-4{
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-5{
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-6{
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-6{
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-12{
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-16{
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-20{
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-auto{
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto{
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-clanScreenEdge{
  margin-left: 1.428rem !important;
  margin-right: 1.428rem !important;
}

.mx-clanFlowStepScreenEdge{
  margin-left: 1.563rem !important;
  margin-right: 1.563rem !important;
}

.mx-clan25percent{
  margin-left: 25% !important;
  margin-right: 25% !important;
}

.mt-0{
  margin-top: 0 !important;
}

.mr-0{
  margin-right: 0 !important;
}

.mb-0{
  margin-bottom: 0 !important;
}

.ml-0{
  margin-left: 0 !important;
}

.mr-1{
  margin-right: 0.25rem !important;
}

.mb-1{
  margin-bottom: 0.25rem !important;
}

.ml-1{
  margin-left: 0.25rem !important;
}

.mt-2{
  margin-top: 0.5rem !important;
}

.mr-2{
  margin-right: 0.5rem !important;
}

.mb-2{
  margin-bottom: 0.5rem !important;
}

.ml-2{
  margin-left: 0.5rem !important;
}

.mt-3{
  margin-top: 0.75rem !important;
}

.ml-3{
  margin-left: 0.75rem !important;
}

.mt-4{
  margin-top: 1rem !important;
}

.mr-4{
  margin-right: 1rem !important;
}

.mb-4{
  margin-bottom: 1rem !important;
}

.ml-4{
  margin-left: 1rem !important;
}

.mt-6{
  margin-top: 1.5rem !important;
}

.mb-6{
  margin-bottom: 1.5rem !important;
}

.mt-8{
  margin-top: 2rem !important;
}

.mt-10{
  margin-top: 2.5rem !important;
}

.mt-12{
  margin-top: 3rem !important;
}

.mt-auto{
  margin-top: auto !important;
}

.mr-auto{
  margin-right: auto !important;
}

.ml-auto{
  margin-left: auto !important;
}

.-mb-32{
  margin-bottom: -8rem !important;
}

.mt-clanFlowHeaderTop{
  margin-top: 10vh !important;
}

.mt-clanFlowContentTop{
  margin-top: 10vh !important;
}

.max-h-50vh{
  max-height: 50vh !important;
}

.max-w-xs{
  max-width: 20rem !important;
}

.max-w-full{
  max-width: 100% !important;
}

.min-h-full{
  min-height: 100% !important;
}

.min-h-clanListItem{
  min-height: 2.86rem !important;
}

.min-h-clanFixedBottom{
  min-height: 3.575rem !important;
}

.min-h-clanNotificationBody{
  min-height: 4rem !important;
}

.opacity-50{
  opacity: 0.5 !important;
}

.overflow-auto{
  overflow: auto !important;
}

.overflow-hidden{
  overflow: hidden !important;
}

.overflow-visible{
  overflow: visible !important;
}

.overflow-y-auto{
  overflow-y: auto !important;
}

.overflow-x-scroll{
  overflow-x: scroll !important;
}

.p-0{
  padding: 0 !important;
}

.p-2{
  padding: 0.5rem !important;
}

.p-3{
  padding: 0.75rem !important;
}

.p-4{
  padding: 1rem !important;
}

.p-clanFlowStepScreenEdge{
  padding: 1.563rem !important;
}

.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1{
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-3{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3{
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4{
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-5{
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-6{
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-8{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-16{
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-clanFlowStepScreenEdge{
  padding-left: 1.563rem !important;
  padding-right: 1.563rem !important;
}

.pt-0{
  padding-top: 0 !important;
}

.pr-1{
  padding-right: 0.25rem !important;
}

.pr-2{
  padding-right: 0.5rem !important;
}

.pb-2{
  padding-bottom: 0.5rem !important;
}

.pt-3{
  padding-top: 0.75rem !important;
}

.pl-3{
  padding-left: 0.75rem !important;
}

.pr-4{
  padding-right: 1rem !important;
}

.pl-4{
  padding-left: 1rem !important;
}

.pr-6{
  padding-right: 1.5rem !important;
}

.pb-8{
  padding-bottom: 2rem !important;
}

.pb-40{
  padding-bottom: 10rem !important;
}

.static{
  position: static !important;
}

.fixed{
  position: fixed !important;
}

.absolute{
  position: absolute !important;
}

.relative{
  position: relative !important;
}

.bottom-0{
  bottom: 0 !important;
}

.left-0{
  left: 0 !important;
}

.top-100\%{
  top: 100% !important;
}

.bottom-100\%{
  bottom: 100% !important;
}

.resize{
  resize: both !important;
}

.shadow-md{
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.shadow-none{
  box-shadow: none !important;
}

.shadow-clan{
  box-shadow: 0px 7px 10px rgba(212, 189, 189, 0.5) !important;
}

.text-left{
  text-align: left !important;
}

.text-center{
  text-align: center !important;
}

.text-right{
  text-align: right !important;
}

.text-justify{
  text-align: justify !important;
}

.text-black{
  --text-opacity: 1 !important;
  color: #000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

.text-gray-500{
  --text-opacity: 1 !important;
  color: #a0aec0 !important;
  color: rgba(160, 174, 192, var(--text-opacity)) !important;
}

.text-red-600{
  --text-opacity: 1 !important;
  color: #e53e3e !important;
  color: rgba(229, 62, 62, var(--text-opacity)) !important;
}

.text-clanGreen-100{
  --text-opacity: 1 !important;
  color: #45dd85 !important;
  color: rgba(69, 221, 133, var(--text-opacity)) !important;
}

.text-clanGray-200{
  --text-opacity: 1 !important;
  color: #878396 !important;
  color: rgba(135, 131, 150, var(--text-opacity)) !important;
}

.text-clanGray-300{
  --text-opacity: 1 !important;
  color: #e0e0e0 !important;
  color: rgba(224, 224, 224, var(--text-opacity)) !important;
}

.text-clanYellow-100{
  --text-opacity: 1 !important;
  color: #fee661 !important;
  color: rgba(254, 230, 97, var(--text-opacity)) !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.capitalize{
  text-transform: capitalize !important;
}

.tracking-normal{
  letter-spacing: 0 !important;
}

.tracking-extrawide{
  letter-spacing: 0.5em !important;
}

.align-middle{
  vertical-align: middle !important;
}

.visible{
  visibility: visible !important;
}

.invisible{
  visibility: hidden !important;
}

.whitespace-normal{
  white-space: normal !important;
}

.whitespace-no-wrap{
  white-space: nowrap !important;
}

.whitespace-pre-wrap{
  white-space: pre-wrap !important;
}

.w-6{
  width: 1.5rem !important;
}

.w-8{
  width: 2rem !important;
}

.w-10{
  width: 2.5rem !important;
}

.w-12{
  width: 3rem !important;
}

.w-48{
  width: 12rem !important;
}

.w-56{
  width: 14rem !important;
}

.w-auto{
  width: auto !important;
}

.w-clanAvatar{
  width: 12.5rem !important;
}

.w-clanProfileAvatar{
  width: 12.5rem !important;
}

.w-3\/4{
  width: 75% !important;
}

.w-4\/5{
  width: 80% !important;
}

.w-11\/12{
  width: 91.666667% !important;
}

.w-full{
  width: 100% !important;
}

.w-clanCardCircle{
  width: 12rem !important;
}

.w-clanProfileAvatarSmall{
  width: 2.15rem !important;
}

.z-10{
  z-index: 10 !important;
}

.z-100{
  z-index: 100 !important;
}

.grid-cols-3auto-middle{
  grid-template-columns: 1fr minmax(0, auto) 1fr !important;
}

.transform{
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
}

.transition{
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform !important;
}

.ease-in-out{
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

@-webkit-keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin{
  to{
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity: .5;
  }
}

@keyframes pulse{
  50%{
    opacity: .5;
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50%{
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.after\:attr-content::after{
  content: attr(title) !important;
  position: absolute !important;
  top: -2.5rem !important;
  right: -25% !important;
  background-color: black !important;
  white-space: normal !important;
  -webkit-transform: translateY(5rem) !important;
          transform: translateY(5rem) !important;
  padding: 0.25rem !important;
  border-radius: 0.5rem !important;
  color: white !important;
}

/* Add any custom utilities to the end of your CSS file */

/* Cleanbeat Theme file, fonts, ionic variables etc goes here */

@import "./colors.css";

@import "./fonts.css";

@import "./variables.css";

html {
  --thumbBG: rgb(135 131 150 / 50%);
}

/* Works on Firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) white;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 20px;
  border: 3px solid white;
}

/* All font sizes are using rem. changing them here scales the entire app */

:root {
  font-size: 14px;
}

.info-card .popover-content {
  width: 100% !important;
  left: unset !important;
  top: unset !important;
  position: relative;
}

.info-card .popover-viewport {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.info-card .popover-wrapper {
  margin-top: auto;
  margin-bottom: 2rem;
  width: 90%;
  max-width: 500px;
}

/* For reactions popover */

.reactions-popover .popover-content {
  overflow: visible;
  background: #e0e0e0 !important;
  padding: 0.5rem;
  border-radius: 9999px;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

/* For date time picker */

.picker-button {
  color: black !important;
}

.picker-opt-selected {
  color: #45dd85 !important;
  font-weight: 800 !important;
}

.tall-modal {
  --min-height: 80%;
}

.transparent-modal .modal-wrapper{
  background: transparent;
}

.fullpage-modal {
  --min-height: 100%;
  --min-width: 100%;
}

p {
  font-size: 1rem;
  line-height: 1.286rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h1 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 3.214rem;
  line-height: 3.571rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h2 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1.714rem;
  line-height: 2rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h3 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1.286rem;
  line-height: 1.571rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h4 {
  font-weight: 800;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.286rem;
  margin-top: 0;
  margin-bottom: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h5 {
  font-weight: 300;
  font-size: 0.8571rem;
  line-height: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

h6 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.286rem;
  margin-top: 0;
  margin-bottom: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.tab-button {
  --bg-opacity: 1;
  background-color: #fafafa;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 3rem;
  height: 3rem;
}

.tab-button-small {
  --bg-opacity: 1;
  background-color: #fafafa;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
}

.clan-list-item {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  padding: 0.75rem;
  min-height: 2.86rem;
  text-align: center;
  vertical-align: middle;
  --bg-opacity: 1;
  background-color: #fafafa;
  background-color: rgba(250, 250, 250, var(--bg-opacity));
  border-radius: 1.78rem;
}

VideoContent {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

VideoContent iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 640px){
}

@media (min-width: 768px){
}

@media (min-width: 1024px){
}

@media (min-width: 1280px){
}

@media (min-width: 320px){
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansRegular";
  src: url("./fonts/NotoSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-ExtraBold.otf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("./fonts/Gilroy-Light.otf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Didact";
  src: url("./fonts/DidactGothic-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
/* ONLY USE THIS FILE FOR GLOBAL CHANGES
// (MOSTLY RESETTING IONIC STLYING) THAT CAN NOT BE DONE
// USING TAILWIND - ANYTHING LOCAL SHOULD BE DONE WITH TAILWIND ClASSES AKA UTILITIES
*/

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-font-family: "notoSans";
  --ion-toolbar-background: none;
  /* Use if needed to offset the app where it's covered by the status bar (iOS) */
  /* --ion-statusbar-padding: 0px; */
  /* padding-top: constant(safe-area-inset-top); */
  /* padding-top: env(safe-area-inset-top); */
}

ion-toolbar {
  --min-height: 0.5rem !important;
  --border-width: 0 !important;
}

.alert-wrapper  {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.alert-wrapper .alert-checkbox-group {
  max-height: 100% !important;
}

.alert-button {
  color: black !important;
}

ion-progress-bar {
  --ion-color-base-rgb: none !important;
}

ion-button {
  --box-shadow: none;
}

ion-segment-button {
  text-transform: none;
}

ion-segment {
  --ion-color-primary: none;
}

ion-segment#clan-gender-segment {
  --ion-color-primary: #fee661;
}

ion-segment#clan-share-segment {
  --background: var(--ion-color-primary);
}

ion-item {
  --border-width: 0px;
  --inner-border-width: 0px;
  --show-full-highlight: 0;
}

ion-item#clan-flowstep-item {
  --padding-start: 0px !important;
}

ion-textarea {
  --padding-bottom: 2rem !important;
  --padding-start: 2rem !important;
  --padding-end: 2rem !important;
}

ion-textarea {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-bottom: 0 !important;
}

.sc-ion-action-sheet-ios-h {
  --button-color: black;
}

#planner_day #task_add_bar ion-button {
  --padding-end: 0.5rem;
  --padding-start: 0;
}

/* IonModal does not allow style attribute */
#task_scheduler_modal {
  --border-radius: 25px;
  --min-width: 280px;
  --width: 70vw;
  --min-height: 400px;
  --height: 60vh;
}

.ion-page-invisible {
  opacity: 1 !important;
}

ion-toast {
  color: #ffffff;
  font-family: var(--ion-font-family);
  font-size: 1rem;
  line-height: 1.286rem;
}

#start-page ion-col ion-input {
  border-width: 1pt;
  border-style: solid;
  border-color: var(--ion-color-primary-shade);
}
